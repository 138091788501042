'use strict';

var processInclude = require('base/util');

$(document).ready(function () {
	setTimeout(function() {
		processInclude(require('./carousel/carousel'));
		$('.recommendations').removeClass('d-none');
	}, 1000);
});
